import U from '../js/utils';
import svg from '../js/svg';
export default async (elID, requiredYN, onclick) => {
  let div;
  const eyeElement = await svg.eye();
  const eye = eyeElement.outerHTML;

  if (requiredYN) {
    div = `
    <div class="input-group">
    <label for="${elID}">Password</label>
      <input type="password" id="${elID}" name="${elID}" required val="password" />
      <div id="eyeDiv" class="" onclick="U.viewPass('${elID}', 'eyeDiv')">${eye}</div>
      <span fb-for="${elID}"></span>
    </div>
  `;
  } else {
    div = `
      <div class="input-group">
        <label for="${elID}">Password</label>
        <input type="password" id="${elID}" name="${elID}" readonly value="password" />
        <div class="absolute right top-10 right-2 cursor-pointer hover:text-primary" onclick="comp.changePasswordBox('${onclick}')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ">
           <path class="" stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
          </svg>
        </div>
       
      </div>;`;
  }

  return U.createHTMLElementFromString(div);
};
