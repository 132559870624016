export class PageNode {
  constructor({ recipe, vars, hdrs }) {
    (this.recipe = recipe), (this.vars = vars), (this.hdrs = hdrs);
  }
  async render() {
    const rec = this.recipe.methods;
    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  }
};
