import './styles/main.scss';
import './assets/images.js';
import U from './js/utils';
import O from './js/core';
import comp from './components/barrel.js';

window.U = U;
window.O = O;
window.comp = comp;

document.addEventListener('DOMContentLoaded', async (e) => {
  window.pgList = {};
  window.thsPage = {};
  window.thsTab = {};
  window.thsSubTab = {};
  window.communal = {};

  const currentPageTitle = history.state ? JSON.parse(history.state).pageTitle : null;

  if (currentPageTitle && currentPageTitle !== 'Login') {
    O.popstate(history.state);
  } else {
    await O.PREP('Login');
  }

  window.addEventListener('popstate', function (event) {
    O.popstate(event.state);
  });
});
