import U from '../js/utils';

export default (elID, value, labelName, svg) => {
  let SVGhtml;
  if (svg) {
    SVGhtml = svg.outerHTML;
  }

  const div = `
    <div id="${elID}Div" class="input-group">
     ${labelName ? `<label for="${elID}">${labelName}</label>` : ''} 
     <p id="${elID}" class="flex items-center">${SVGhtml || ''}${value}</p>
    </div>
    `;

  return U.createHTMLElementFromString(div);
};
