import comp from '../components/barrel';

export default {
  application_uuid: null,
  marriages: null,
  party1_name: null,
  party2_name: null,
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;
    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-maritial-detail', vars));
    this.recipe.methods.push(await O.mPOST('communal/marriages', vars));
  },
  async render() {
    const rec = this.recipe.methods;
    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const MarriageData = data[`application/get-maritial-detail`].data;
    console.log('MarriageData', MarriageData);
    this.marriages = data[`communal/marriages`].data;
    this.party1_name = MarriageData.party1.full_name;
    this.party2_name = MarriageData.party2 ? MarriageData.party2.full_name : null;

    //Party 1
    const div1 = U.divAtt('div', 'class', 'mb-4');
    const heading1 = U.divAtt('div', 'class', 'mb-2');
    heading1.innerHTML = `Party 1: ${this.party1_name}`;
    div1.appendChild(heading1);
    let marriageDD = await comp.select(`party1Marriage`, this.marriages, 'marriage_uuid', 'short_text', 'Marriage Status', 1);
    div1.appendChild(marriageDD);
    await U.id('marriageForm').appendChild(div1);
    U.id(`party1Marriage`).value = MarriageData.party1.marriage_uuid;

    //Party 2
    if (MarriageData.party2) {
      const div2 = U.divAtt('div', 'class', 'mb-4');
      const heading1 = U.divAtt('div', 'class', 'mb-2');
      heading1.innerHTML = `Party 1: ${this.party2_name}`;
      div2.appendChild(heading1);
      let marriageDD = await comp.select(`party2Marriage`, this.marriages, 'marriage_uuid', 'short_text', 'Marriage Status', 1);
      div2.appendChild(marriageDD);
      await U.id('marriageForm').appendChild(div2);
    }

    setTimeout(() => {
      MarriageData.party1.marriage_uuid && (U.id(`party1Marriage`).value = MarriageData.party1.marriage_uuid);
      U.id(`party1Marriage`).setAttribute('onchange', `thsSubTab.updateMarriage()`);
      if (MarriageData.party2) {
        MarriageData.party2.marriage_uuid && (U.id(`party2Marriage`).value = MarriageData.party2.marriage_uuid);
        U.id(`party2Marriage`).setAttribute('onchange', `thsSubTab.updateMarriage()`);
      }
    }, 1000);
  },
  async updateMarriage() {
    let isValid = await U.validateForm('marriageForm');
    if (!isValid) {
      return;
    }

    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.party1_marriage_uuid = U.id('party1Marriage').value;
    if (U.id('party2Marriage')) {
      vars.party2_marriage_uuid = U.id('party2Marriage').value;
    }

    O.PREP([await O.mPOST('application/ups-maritial-detail', vars)])
      .then(async (res) => {
        const msg = res['application/ups-maritial-detail'].status;
        comp.toast(msg);
      })
      .catch(err => {console.log(err)});
  },
  async savePage(nextPrev) {
    const method = 'application/val-app-page';
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.page_name = 'ADs_MaritalStatus';
    O.PREP([await O.mPOST(method, vars)])
      .then(async (res) => {
        comp.toast(res[method].status);
        thsPage.pageProgress(res[method].data, nextPrev);
      })
      .catch(err => {console.log(err)});
  },
};
