export default async () => {

  const getNav = 'navigation/get-navigation-elements';
  const vars = {};
  const {value} = await O.getCookie('ewttgpvwwkf');
  vars.user_uuid = value;

  const res = await O.PREP([await O.mGET(getNav, vars)]);
  const navElements = res[getNav].data;

  
  let navList = '';

  for (let nav in navElements) {
    const el = navElements[nav];

    const navItem = `
      <a id="${el.html_text}" class="nav-item" onclick="O.PREP('${el.html_text}'), U.navSel(this.id)">${el.short_text}</a>
    `;

    let subList = '';

    for (let sub of el.subMenu) {
      subList += `
        <div class="subItem" onclick="O.PREP('${sub.html_text}'), U.navSel('${el.html_text}')"">${sub.short_text}</div>
      `;
    }
    navList += `
      <li>
        ${navItem}
        <div class="subMenu">
          ${subList}
        </div>
      </li>
    `;
  }

  const div = `
        <nav>

        <div onclick="O.PREP('HomePage')">
        <img  src="legatus-logo-nav.png" alt="Legatus Logo">
        </div>
          <ul>
            ${navList}
          </ul>
        <div class="flex items-center">
      
      
        <div id="langDiv">
            <svg class="langHomeSVG" xmlns=" http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21.07" height="21.07" viewBox="0 0 21.07 21.07">
                <path class="cls-3" d="M21.07,10.55A10.54,10.54,0,1,1,10.55,0h0A10.55,10.55,0,0,1,21.07,10.55m-7-4c-1.24,0-2.4.08-3.56.08S8.2,6.56,7,6.53a19.34,19.34,0,0,0-.38,3.39h7.84c-.13-1.15-.26-2.27-.39-3.39m-7.45,4.6A18.77,18.77,0,0,0,7,14.56a54.66,54.66,0,0,1,7,0,18.75,18.75,0,0,0,.38-3.42Zm.69,4.6a.85.85,0,0,0,0,.17,9,9,0,0,0,1.34,2.82,3.09,3.09,0,0,0,1,.94,1.48,1.48,0,0,0,1.52,0,3.18,3.18,0,0,0,.7-.52,6.43,6.43,0,0,0,1.44-2.39c.12-.33.23-.68.36-1a49.27,49.27,0,0,0-6.43,0m0-10.4a49,49,0,0,0,6.41,0s0-.09,0-.13a9.46,9.46,0,0,0-1.44-3,2.69,2.69,0,0,0-1.23-.95,1.68,1.68,0,0,0-1.8.48A7.75,7.75,0,0,0,7.31,5.34M5.79,6.42l-.12,0L2.51,6c-.14,0-.19,0-.24.15a8.26,8.26,0,0,0-.79,2.09c-.13.55-.2,1.1-.31,1.67H5.41c.12-1.17.25-2.32.38-3.5m9.49,8.23L18,15c.24,0,.54.14.7,0s.26-.43.36-.66a9.14,9.14,0,0,0,.8-3.21h-4.2l-.38,3.5m4.57-4.72s0-.06,0-.08a9.57,9.57,0,0,0-1-3.66A.28.28,0,0,0,18.48,6c-.67.1-1.34.18-2,.27l-1.18.13.38,3.51ZM1.21,11.14A9,9,0,0,0,2.31,15c0,.06.18.1.26.09l2-.26,1.17-.13L5.4,11.14ZM18,16.19l-.11,0-2.8-.31c-.05,0-.13.08-.15.14-.17.46-.29.95-.49,1.39-.33.71-.71,1.39-1.06,2.08A9.4,9.4,0,0,0,18,16.19M3.09,4.88l.11,0L6,5.24c.15,0,.15-.08.18-.17.23-.63.43-1.27.7-1.88s.57-1,.87-1.57A9.33,9.33,0,0,0,3.09,4.88M13.27,1.59a11,11,0,0,1,1.66,3.53.27.27,0,0,0,.19.11l1.63-.16L18,4.9a9.54,9.54,0,0,0-4.73-3.31M7.78,19.47,7.47,19a10.72,10.72,0,0,1-1.28-2.92c-.05-.19-.11-.28-.33-.25-.51.07-1,.1-1.54.16l-1.25.17a9.44,9.44,0,0,0,4.7,3.3" transform="translate(0 0)"></path>
                </svg>
            <select id="langDD">
            <option value="1">English</option>
            <option value="2">Afrikaans</option>
            </select>

        </div>

          <span>
            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="19" cy="19" r="19" fill="white"/>
            <path d="M25.6663 26.5V24.8333C25.6663 23.9493 25.3152 23.1014 24.69 22.4763C24.0649 21.8512 23.2171 21.5 22.333 21.5H15.6663C14.7823 21.5 13.9344 21.8512 13.3093 22.4763C12.6842 23.1014 12.333 23.9493 12.333 24.8333V26.5" stroke="#3B71CA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.0003 18.1667C20.8413 18.1667 22.3337 16.6743 22.3337 14.8333C22.3337 12.9924 20.8413 11.5 19.0003 11.5C17.1594 11.5 15.667 12.9924 15.667 14.8333C15.667 16.6743 17.1594 18.1667 19.0003 18.1667Z" stroke="#3B71CA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="subMenu">
            <div class="subItem" onclick="O.PREP('Profile'), U.navSel()"">My profile</div>
            <div class="subItem" onclick="O.logout()">Logout</div>
            </div>
          </span>
        </div>

        </nav>
  `;

  return U.id('mainNavigation').appendChild(U.createHTMLElementFromString(div));;
};
