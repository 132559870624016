import pages from '../pages/barrel';
import comp from '../components/barrel';

export default {
  async prepare() {},
  async render() {
    const rec = this.recipe.methods;
    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const querySearchString = window.location.search;
    const urlParams = new URLSearchParams(querySearchString);
    const token_key = urlParams.get('token_key');
    if (token_key) {
      await U.createCookie('tokdcbsll', token_key);
      U.hide('loginPage');
      U.unhide('resetPage');
    }
    window.addEventListener('keyup', thsPage.onEnter);
  },
  async login() {
    U.loaderBtn(1, 'loginBtn');
    const vars = {};
    vars.email_address = U.id('username').value;
    vars.userpass = U.id('password').value;

    await O.PREP([await O.mPOST('login/login', vars)])
      .then(async (res) => {
        const data = res['login/login'].data;

        U.loaderBtn(0, 'loginBtn');
        window.removeEventListener('keyup', thsPage.onEnter);
        // Set cookies
        const language_id = data.language_id ? data.language_id : 1;
        const {user_uuid,token,refreshToken} = data;
        await O.initSession({user_uuid, token, refreshToken, language_id});

        document.body.innerHTML = pages.Master;
        await comp.navigation();
        await O.PREP('HomePage');
        await U.getCommunalDropdowns();
        
      })
      .catch((err) => {
        //Errorbox here
        U.loaderBtn(0, 'loginBtn');
      });
  },
  async onEnter(e){
    if (e.key === 'Enter') {
      thsPage.login();
    }
  },
  async forgotPass() {
    U.loaderBtn(1, 'forgotPassBtn');
    const vars = {};
    vars.email_address = U.id('emailReset').value;

    await O.PREP([await O.mPOST('login/forgot-password', vars)]).then((res) => {
      console.log('forgot', res);
      const msg = `${res['login/forgot-password'].status}<br>Please check your email inbox for the reset link.`;
      comp.toast(msg);
      const onclick = "O.PREP('Login')";
      comp.modal('Password reset', msg, onclick);

      U.loaderBtn(0, 'forgotPassBtn');
    });
  },
  async updatePass() {
    if (!U.id('password').value) {
      return alert('No password');
    }

    const {value} = await O.getCookie('tokdcbsll');
    const vars = {};
    vars.token_key = value;
    vars.userpass = U.id('password').value;

    await O.PREP([await O.mPOST('login/update-password', vars)]).then((res) => {

      const msg = res['login/update-password'].status;
      comp.toast(msg);

      document.cookie = `tokdcbsll=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  },
  showForgot() {
    U.hide('loginDiv');
    U.unhide('passDiv');
  },
  showLogin() {
    U.unhide('loginDiv');
    U.hide('passDiv');
  },
};
