import U from '../js/utils';

export default async (elID, value, labelName, requiredYN) => {
    return new Promise(async (resolve, reject) => {
      try {
  const div = `
    <div id="${elID}Div" class="input-group">

     ${labelName ? `<label for="${elID}">${labelName}</label>` : ''} 
      <input type="text" id="${elID}" name="${elID}" value="${value}" val="text" ${requiredYN ? 'required' : ''} placeholder="${labelName}"/>
      <span fb-for="${elID}"></span>
    
    </div>
    `;
  resolve(U.createHTMLElementFromString(div));
} catch (error) {
  console.error(error);
  reject(error);
}});
};
