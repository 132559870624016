import comp from '../components/barrel';
export default {
  async prepare() {

    this.recipe.methods.push(await O.mGET('communal/languages'));
    this.recipe.methods.push(await O.mGET('communal/provinces'));
    this.recipe.methods.push(await O.mGET('communal/marketers'));
    this.recipe.methods.push(await O.mGET('communal/offices'));
    // this.recipe.methods.push(await O.mGET('communal/roles'));
    this.recipe.methods.push(await O.mGET('communal/businesses'));
    this.recipe.methods.push(await O.mGET('communal/titles'));
    this.recipe.methods.push(await O.mGET('communal/branches'));
  },
  async render() {
    const rec = this.recipe.methods;
    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(res) {
    console.log('res', res);

    const dataLangDD = await res[`communal/languages`].data;
    const dataProvDD = await res[`communal/provinces`].data;
    const dataMarketDD = await res[`communal/marketers`].data;
    const dataOfficesDD = await res[`communal/offices`].data;
    const dataRolesDD = await res[`communal/roles`].data;
    const dataBusDD = await res[`communal/businesses`].data;
    const dataTitlesDD = await res[`communal/titles`].data;
    const dataBrachesDD = await res[`communal/branches`].data;

    //FORM ==========
    const form = U.id('user-form');

    //   Registration date
    const RegDateInput = await comp.inputDate('reg_date', '', 'Registration date', 1);
    form.appendChild(RegDateInput);

    //Area
    let areaInput = await comp.inputText('office_area', '', 'Area', 1);
    form.appendChild(areaInput);

    //Province
    let provDD = await comp.select('dd_office_province', dataProvDD, 'province_uuid', 'long_text', 'Province', 1);
    form.appendChild(provDD);

    //Broker Number
    let brokerInput = await comp.inputText('broker_nr', '', 'Broker number', 1);
    form.appendChild(brokerInput);

    //Language
    let langDD = await comp.select('language_id', dataLangDD, 'language_id', 'long_text', 'Language', 1);
    form.appendChild(langDD);

    // //Surname
    // let surnameInput = await comp.inputText('s_name', data.s_name, 'Surname', 1);
    // form.appendChild(surnameInput);
    // //Email
    // let emailInput = await comp.inputEmail('email_address', data.email_address, 'Email', 1);
    // form.appendChild(emailInput);
    // //Mobile
    // let mobInput = await comp.inputTel('cell_nr', data.cell_nr, 'Mobile number', 1);
    // form.appendChild(mobInput);
    // // Language DropDown
    // let langDD;
    // langDD = await comp.select('language_id', dataLangDD, 'language_id', 'long_text', 'Language', 1);
    // form.appendChild(langDD);
    // U.id('language_id').value = data.language_id;
  },
  async addUser() {
    // if (!U.validateForm('profile-form')) {
    //   return;
    // }

    const vars = {};
    vars.email_address = 'mike@basrus.co.za';
    vars.cell_nr = '0987654321';
    vars.f_namce = 'Mike';
    vars.s_name = 'M&Ms';
    vars.language_uuid = 1; //'51ee0a08-f964-4f8a-820b-e425467d92f1';
    vars.advisor = 1;
    vars.reg_date = '2024/01/04';
    vars.office_area = 'Johannesburg';
    vars.broker_nr = '12345';
    vars.company_employee = 1;
    vars.active = 1;
    vars.will_drafter = 1;
    vars.pdf_will = 1;
    vars.office__province_uuid = '6fd275e7-da96-4fda-b2cb-43c915967715';
    vars.marketer_uuid = 'd3fdd8db-a6fa-4ddc-add9-f26a8110124e';
    vars.branch_uuids = [];
    vars.office_uuid = '8edc0cfa-803a-4260-a212-cd527ea03bdd';
    vars.role_uuid = 1;
    vars.reg_nr = 1;
    vars.fsp_nr = 1;
    vars.fsp_registered = 1;
    vars.business_uuid = '9f828add-6893-4d8e-bb28-c044c1c62abc';
    vars.initials = 1;
    vars.nickname = 1;
    vars.id_nr = '9008145678098';
    vars.passport_nr = '';
    vars.secondary_email = 'korneliusSagarius2@test.com';
    vars.tell_nr = '0117921234';
    vars.fax_nr = '';
    vars.title_uuid = '5dcb4559-5665-42a9-ab85-17d11cd3a18c';
    vars.business_address = 1;
    vars.business_suburb = 1;
    vars.business_city = 1;
    vars.business_area_code = 1;
    vars.business__province_uuid = '9f828add-6893-4d8e-bb28-c044c1c62abc';
    vars.postal_address = 1;
    vars.postal_suburb = 1;
    vars.postal_city = 1;
    vars.postal_area_code = 1;
    vars.postal__province_uuid = '6fd275e7-da96-4fda-b2cb-43c915967715';

    O.PREP([await O.mPOST('users/user', vars)])
      .then(async (res) => {
        const msg = res['users/user'].status;
        comp.toast(msg);
      })
      .catch(err => {console.log(err)});
  },
};
