import U from '../js/utils';
export default async (elID, isChecked, labelName, onchange) => {
  return new Promise(async (resolve, reject) => {
    try {
      const div = `
    <div id="${elID}Div" class="input-group">
      <label 
     
      class="flex items-center font-normal">
        <input class="w-4 m-1 ml-0" type="checkbox" id="${elID}" name="${elID}" 
        ${isChecked ? 'checked' : ''}
          ${onchange ? `onchange="${onchange}"` : ''} 
         />
        ${labelName}
      </label>
    </div>
  `;
      resolve(U.createHTMLElementFromString(div));
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
