import svg from '../js/svg';

export default {
  application_uuid: null,
  relationships: null,
  party1_name: null,
  party2_name: null,
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;
    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-gaurdian-detail', vars));
  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const guardians = data['application/get-gaurdian-detail'].data.guardian_list;
    const guardians_yn = data['application/get-gaurdian-detail'].data.guardian_yn;
    const parties = data[`application/get-gaurdian-detail`].data.users;
    this.party1_name = parties.party1_name;
    this.party2_name = parties.party2_uuid ? parties.party2_name : null;

    // -------------------------------
    // Guardian Checkbox
    let no_guardians = await comp.toggle('no_guardians', guardians_yn, 'Guardians', `thsSubTab.toggleGuardian();`);
    U.id(`guardian_yn`).appendChild(no_guardians);
    if (!guardians_yn) {
      U.toggleHide('guardian_section');
    }

    // -------------------------------
    // Guardian Table
    await this.buildGTable(guardians);
  },
  toggleGuardian() {
    this.submitNewGuardian();
    U.toggleHide('guardian_section');
  },
  async buildGTable(guardians) {
    const table = U.id(`guardian_list`);

    const thead = U.divAtt('thead');
    const headRow = thead.insertRow();
    const nameH = headRow.insertCell(0);
    nameH.innerHTML = 'Name';
    const emailH = headRow.insertCell(1);
    emailH.innerHTML = 'Email address';
    const relParty1H = headRow.insertCell(2);
    relParty1H.innerHTML = 'Party 1';
    const relParty2H = headRow.insertCell(3);
    relParty2H.innerHTML = 'Party 2';
    const removeH = headRow.insertCell(4);
    removeH.innerHTML = 'Remove';
    thead.appendChild(headRow);

    table.innerHTML = '';
    table.appendChild(thead);
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);
    if (guardians.length) {
      for (let guardian of guardians) {
        const guardRow = tbody.insertRow();
        const name = guardRow.insertCell(0);
        name.innerHTML = `${guardian.f_name} ${guardian.s_name}`;
        const email = guardRow.insertCell(1);
        email.innerHTML = guardian.email_address;
        const rel_party1 = guardRow.insertCell(2);
        rel_party1.innerHTML = guardian.rel_party1;
        const rel_party2 = guardRow.insertCell(3);
        rel_party2.innerHTML = guardian.rel_party2;
        const removeIcon = guardRow.insertCell(4);
        const onclick = `thsSubTab.removeGuardian('${guardian.application_guardian_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      }
    } else {
      //No Guadians ---
      const guardRow = tbody.insertRow();
      const noG = guardRow.insertCell(0);
      noG.innerHTML = 'No Guardians.';
      noG.colSpan = 5;
    }
  },
  async addGuardian() {
    const inforObj = {
      relationship: [this.party1_name],
      email_address: 1,
      cell_nr: 1,
      physical_address: 1,
    };
    this.party2_name && inforObj.relationship.push(this.party2_name);
    const data = await U.addCivillian(inforObj);
    if (data) {
      console.log('gaurdian data', data);
      this.submitNewGuardian(data.user_uuid);
    }
  },
  async submitNewGuardian(civillian_uuid) {
    const method = 'application/ups-gaurdian-detail';
    const vars = {};
    vars.guardian_yn = U.id('no_guardians').checked;
    vars.application_uuid = this.application_uuid;
    if (civillian_uuid) {
      vars.civillian_uuid = civillian_uuid;
    }

    O.PREP([await O.mPOST(method, vars)])
      .then(async (res) => {
        comp.toast(res[method].status);
        // O.changeSubTab('ADs_Guardian');
        this.buildGTable(res[method].data.guardian_list);
      })
      .catch(err => {console.log(err)});
  },
  async removeGuardian(application_guardian_uuid) {
    const method = 'application/rmv-gaurdian-detail';

    const vars = {};
    vars.application_guardian_uuid = application_guardian_uuid;
    vars.application_uuid = this.application_uuid;

    O.PREP([await O.mPOST(method, vars)])
      .then(async (res) => {
        comp.toast(res[method].status);
        this.buildGTable(res[method].data.guardian_list);
        // O.changeSubTab('ADs_Guardian');
      })
      .catch(err => {console.log(err)});
  },
  async savePage(nextPrev) {
    const method = 'application/val-app-page';
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.page_name = 'ADs_Guardian';
    O.PREP([await O.mPOST(method, vars)])
      .then(async (res) => {
        comp.toast(res[method].status);
        thsPage.pageProgress(res[method].data, nextPrev);
      })
      .catch(err => {console.log(err)});
  },
};
