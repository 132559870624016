import U from '../js/utils';
export default async (io, msg) => {
  if (io) {
    const div = `

    <div id="loader" class="fixed justify-center flex items-center h-screen w-screen bg-opacity-50 bg-black z-50 ">

    <svg class="animate-spin -ml-1 mr-3 h-20 w-20 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-100" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class=" fill-primary" fill="" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    </div>

     `;

    const loader = U.createHTMLElementFromString(div);
    document.body.prepend(loader);
  } else {
    const loader = U.id('loader');
    document.body.removeChild(loader);
  }
};
