import comp from '../components/barrel';
export default {
  async prepare() {
    const {value} = await O.getCookie('ewttgpvwwkf');
    this.recipe.methods.push(await O.mGET(`myProfile/profile/${value}`));
    this.recipe.methods.push(await O.mGET('communal/languages'));
  },
  async render() {
    const rec = this.recipe.methods;
    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(res) {

    const {value} = await O.getCookie('ewttgpvwwkf');
    const data = await res[`myProfile/profile/${value}`].data[0];
    const dataLangDD = await res[`communal/languages`].data;

    //FORM ==========
    const form = U.id('profile-form');
    U.id('user-name-header').innerHTML = `${data.f_name} ${data.s_name}`;

    //Name
    let nameInput = await comp.inputText('f_name', data.f_name, 'Full names', 1);
    form.appendChild(nameInput);
    //Surname
    let surnameInput = await comp.inputText('s_name', data.s_name, 'Surname', 1);
    form.appendChild(surnameInput);
    //Email
    let emailInput = await comp.inputEmail('email_address', data.email_address, 'Email', 1);
    form.appendChild(emailInput);
    //Mobile
    let mobInput = await comp.inputTel('cell_nr', data.cell_nr, 'Mobile number', 1);
    form.appendChild(mobInput);
    // Language DropDown
    let langDD;
    langDD = await comp.select('language_id', dataLangDD, 'language_id', 'long_text', 'Language', 1);
    form.appendChild(langDD);
    U.id('language_id').value = data.language_id;

    let inputPass = await comp.inputPassword('password_fake', 0, `profile.updatePassword()`);
    form.appendChild(inputPass);
  },
  async updateProfile() {
    // if (!U.validateForm('profile-form')) {
    //   return;
    // }

    const vars = {};
    vars.f_name = U.id('f_name').value;
    vars.s_name = U.id('s_name').value;
    vars.email_address = U.id('email_address').value;
    vars.cell_nr = U.id('cell_nr').value;
    vars.language_id = U.id('language_id').value;

    O.PREP([await O.mPOST('myProfile/profile', vars)])
      .then(async (res) => {
        U.id('user-name-header').innerHTML = `${U.id('f_name').value} ${U.id('s_name').value}`;
        console.log('Profile updated', res);
        const msg = res['myProfile/profile'].status;
        comp.toast(msg);
      })
      .catch(err => {console.log(err)});
  },
  async updatePassword() {
    const newPassword1 = U.id('newPassword1').value;
    const newPassword2 = U.id('newPassword2').value;

    if (newPassword1 === newPassword2) {
      U.loaderBtn(1, 'updPass-btn');
      const vars = {};
      vars.userpass = U.id('pass').value;

      O.PREP([await O.mPOST('myProfile/password', vars)])
        .then(async (res) => {
          console.log('Pass updated', res);
          const msg = res['myProfile/password'].status;
          comp.toast(msg);

          U.loaderBtn(0, 'updPass-btn');
        })
        .catch(() => {
          U.loaderBtn(0, 'updPass-btn');
          console.error();
        });
    } else {
      alert('passwords not the same');
      U.loaderBtn(0, 'updPass-btn');
    }
  },
};
