import U from '../js/utils';
export default async (elID, value, labelName, requiredYN) => {
  const div = `
    <div id="${elID}Div" class="input-group col-span-full ">
      ${labelName ? `<label for="${elID}">${labelName}</label>` : ''}
      <textarea id="${elID}" name="${elID}" val="textarea" ${requiredYN ? 'required' : ''} resize-none>${value}</textarea>
      <span fb-for="${elID}"></span>
    </div>
  `;

  return U.createHTMLElementFromString(div);
};
