import U from '../js/utils';

export default async (elID, partiesArr, requiredYN) => {
  const opts = await U.buildOptsDD(communal.relationships, 'relationship_uuid', 'short_text');

  let checkboxes = '';
  if (partiesArr.length > 1) {
    checkboxes = `<div classs="">
   <label class="flex items-center font-normal">
        <input class="w-4 m-1 ml-0" type="checkbox" id="${elID}_party1" name="${elID}_party1" checked />Relationship refers to ${partiesArr[0]}
      </label>
      <label class="flex items-center font-normal">
        <input class="w-4 m-1 ml-0" type="checkbox" id="${elID}_party2" name="${elID}_party2" />Relationship refers to ${partiesArr[1]}
      </label>      
      </div>`;
  }

  const div = `
    <div id="${elID}Div" class="input-group">
    <label for="${elID}">Relationship</label>
      <select id="${elID}" name="${elID}" val="select" 
      ${requiredYN ? 'required' : ''} 
      >
      ${opts}
      </select>
      ${checkboxes}
     <span fb-for="${elID}"></span>
    </div>
  `;

  const element = U.createHTMLElementFromString(div);
  return element;
};
