
import config from '../js/config';

export class Recipe {
  constructor( methods = [], endPoint = config.apiURL) {
    this.endPoint = endPoint;
    this.methods = methods;
    this.div = "mainContent";
    this.scroll = 1;
    this.pageType = 'main'
  }
}
