import U from '../js/utils';

export default async (status) => {
  const tagClassName = status.replace(/\s/g, '');
  const div = `
    <div class="statusTag ${tagClassName}">
     ${status}
    </div>
  `;

  return U.createHTMLElementFromString(div);
};
