import U from '../js/utils';

export default async (elID, optsArr, requiredYN) => {
  let required = requiredYN ? 'required' : '';

  const div = `
    <div id="${elID}Div" class="input-group">
    
${optsArr
  .map(
    (radio, index) =>
      `<label>
        <input type="radio" name="${elID}" value="${radio}" val="radio" 
        ${index === optsArr.length - 1 ? `${required}` : ''}>  
        ${radio}
      </label>
      <br />`
  )
  .join('')}
     ${requiredYN ? `<span fb-for="${elID}" style="bottom: -8px;"></span>` : ''}
    </div>
    `;

  return U.createHTMLElementFromString(div);
};
