import comp from '../components/barrel';

export default {
  async prepare() {},
  async render() {
    const rec = this.recipe.methods;
    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {},
  async test() {},
};
