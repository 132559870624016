export default {
    async prepare() {
    },
    async render() {
        const rec = this.recipe.methods;
        let data = {};
        if (rec.length) {
          data = await O.PREP(rec);
        }
        await this.loadPage(data);
    },
    async loadPage(data) {
    },
    async test() {


        const vars1 = {};
        vars1.test = 'yes';
        '2/4/5'


        const getReq = await O.mGET('waffles', [2,4]);
        const getReq2 = await O.mPOST('waffles', {test: 'yes'});

        // getReq.endPoint = 'http://localhost:3111/captainWaffles/api/v1/';

        O.PREP([getReq,getReq2])
        .then(async () => {
            await O.PREP('NewPage');
        }).catch(console.log(error))

    }
}