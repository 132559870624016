import U from '../js/utils';
export default async (elID, isChecked, labelName1, labelName2, onchangeName) => {
  const div = `
  
    <div id="${elID}Div" class="flex justify-center text-center">
      <div class="toggle-container">
        <input type="checkbox" id="${elID}" name="${elID}" ${isChecked ? 'checked' : ''}  class="sr-only w-full" ${onchangeName ? `onchange="${onchangeName}"` : ''}/>
        <label for="${elID}" class="false">${labelName1}</label>
         <label for="${elID}" class="true">${labelName2}</label>
    </div>
  `;

  return U.createHTMLElementFromString(div);
};
