import U from '../js/utils';
export default async (elID, value, labelName, requiredYN) => {
  const div = `
    <div id="${elID}Div" class="input-group">
      ${labelName ? `<label for="${elID}">${labelName}</label>` : ''}
      <input type="date" id="${elID}" name="${elID}" value="${value}" ${requiredYN ? 'required' : ''} />
      <span fb-for="${elID}"></span>
    </div>
  `;

  return U.createHTMLElementFromString(div);
};
