import U from '../js/utils';

export default async (NumOfSteps) => {
  let progressStepsHTML = '';

  for (let i = 1; i <= NumOfSteps; i++) {
    if (i === 1) {
      progressStepsHTML += `
      <div id="${i}_progressNum" class="progressActive">${i}</div>
      <div id="${i}_progressBar" class="progressActive"></div>
    `;
    } else if (i === NumOfSteps) {
      progressStepsHTML += ` <div id="${i}_progressNum">${i}</div>`;
    } else {
      progressStepsHTML += `
      <div id="${i}_progressNum">${i}</div>
      <div id="${i}_progressBar"></div>
    `;
    }
  }

  const div = `
    <div id="progressSteps" class="progress">
      ${progressStepsHTML}
    </div>
  `;

  const element = U.createHTMLElementFromString(div);
  return element;
};
