import { PageNode } from "./pageNode";
import { Recipe } from "./recipe";
import { Plate } from "./plate";
import { BasePage } from "./basePage";

export default {
  PageNode,
  Recipe,
  Plate,
  BasePage,
};
