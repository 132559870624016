import Login from './Login';
import HomePage from './HomePage';
import Profile from './Profile';
import ManageApplications from './ManageApplications';
import AddApplication from './AddApplication';
import Reports from './Reports';
import ManageUsers from './ManageUsers';
import AddUser from './AddUser';
import VAt_Summary from './VAt_Summary';
import VAt_AppDetails from './VAt_AppDetails';
import ADs_PersonalDetails from './ADs_PersonalDetails';
import ADs_MaritalStatus from './ADs_MaritalStatus';
import ADs_ChildrenDetails from './ADs_ChildrenDetails';
import ADs_Heirs from './ADs_Heirs';
import ADs_Trust from './ADs_Trust';
import ADs_Guardian from './ADs_Guardian';
import ADs_Equity from './ADs_Equity';
import ADs_Devise from './ADs_Devise';
import ViewApplication from './ViewApplication';
import Will from './Will';
import HistoryLog from './HistoryLog';
import ManageOrganisation from './ManageOrganisation';
import AddOrganisation from './AddOrganisation';


export default {
  Login,
  HomePage,
  Profile,
  ManageApplications,
  AddApplication,
  ViewApplication,
  VAt_Summary,
  VAt_AppDetails,
  ADs_PersonalDetails,
  ADs_MaritalStatus,
  ADs_ChildrenDetails,
  ADs_Devise,
  Will,
  HistoryLog,
  Reports,
  ManageUsers,
  AddUser,
  ManageOrganisation,
  AddOrganisation,
  ADs_Heirs,
  ADs_Trust,
  ADs_Guardian,
  ADs_Equity,
};
