import Master from './Master.html';
import Login from './Login.html';
import HomePage from './HomePage.html';
import Profile from './Profile.html';
import ManageApplications from './ManageApplications.html';
import AddApplication from './AddApplication.html';
import Reports from './Reports.html';
import ManageUsers from './ManageUsers.html';
import AddUser from './AddUser.html';
import ManageOrganisation from './ManageOrganisation.html';
import AddOrganisation from './AddOrganisation.html';
import ViewApplication from './ViewApplication.html';
import VAt_Summary from './VAt_Summary.html';
import VAt_AppDetails from './VAt_AppDetails.html';
import ADs_PersonalDetails from './ADs_PersonalDetails.html';
import ADs_MaritalStatus from './ADs_MaritalStatus.html';
import ADs_ChildrenDetails from './ADs_ChildrenDetails.html';
import ADs_Heirs from './ADs_Heirs.html';
import ADs_Trust from './ADs_Trust.html';
import ADs_Guardian from './ADs_Guardian.html';
import ADs_Equity from './ADs_Equity.html';
import ADs_Devise from './ADs_Devise.html';
import Will from './Will.html';
import HistoryLog from './HistoryLog.html';

export default {
  Master,
  Login,
  HomePage,
  Profile,
  ManageApplications,
  AddApplication,
  Reports,
  ManageUsers,
  AddUser,
  ManageOrganisation,
  AddOrganisation,
  ViewApplication,
  VAt_Summary,
  VAt_AppDetails,
  ADs_PersonalDetails,
  ADs_MaritalStatus,
  ADs_ChildrenDetails,
  ADs_Devise,
  Will,
  HistoryLog,
  ADs_Heirs,
  ADs_Trust,
  ADs_Guardian,
  ADs_Equity,
};
