import comp from '../components/barrel';
import svg from '../js/svg';

export default {
  application_uuid: null,
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;

    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-app-summary', vars));
  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const appSummaryData = data[`application/get-app-summary`].data[0];

    // STATUS -------
    const status_row = U.id('status_row');
    let statusText = await comp.statusTag(appSummaryData.status);
    status_row.appendChild(statusText);

    // ADVISOR -------
    const advisor_row = U.id('advisor_row');
    let nameText = comp.displayText('advisorName', appSummaryData.advisor, 'Name');
    let codeText = comp.displayText('advisorCode', 'TBC', 'Code');
    let tell_nrText = comp.displayText('advisorTel', appSummaryData.advisor_cell_nr, 'Tel no.');
    let email_addressText = comp.displayText('advisorEmail', appSummaryData.advisor_email_address, 'Email');
    advisor_row.appendChild(nameText);
    advisor_row.appendChild(codeText);
    advisor_row.appendChild(tell_nrText);
    advisor_row.appendChild(email_addressText);

    // WILL DETAILS -------
    const willDetails_row = U.id('willDetails_row');
    let will_nrText = comp.displayText('will_nr', 'TBC', 'Will number');
    let testamentText = comp.displayText('testament', appSummaryData.testament, 'Testament');
    let crossIcon = svg.crossCircle();
    let tickIcon = svg.tickCircle();
    let will_typeText = comp.displayText('app_type', 'Amended will', '', appSummaryData.app_type === 'Amended will' ? tickIcon : crossIcon);
    let offshoreText = comp.displayText('offshore_will', 'Offshore will', '', appSummaryData.offshore_will ? tickIcon : crossIcon);
    willDetails_row.appendChild(will_nrText);
    willDetails_row.appendChild(testamentText);
    willDetails_row.appendChild(will_typeText);
    willDetails_row.appendChild(offshoreText);

    // PERSONAL DETAILS -------
    const personalDetails_row = U.id('personalDetails_row');
    //party 1
    let p1Name = comp.displayText('P1name', appSummaryData.party1_full_name, 'Party 1: Name');
    let p1ID;
    if (appSummaryData.party1_id_nr) {
      p1ID = comp.displayText('p1ID', appSummaryData.party1_id_nr, 'Party 1: ID');
    } else {
      p1ID = comp.displayText('p1ID', appSummaryData.party1_passport_nr, 'Party 1: Passport Number');
    }
    personalDetails_row.appendChild(p1Name);
    personalDetails_row.appendChild(p1ID);
    //party 2
    if (appSummaryData.party2_full_name) {
      let p2Name = comp.displayText('p2name', appSummaryData.party2_full_name, 'Party 2 : Name');
      let p2ID;
      if (appSummaryData.party1_id_nr) {
        p2ID = comp.displayText('p2ID', appSummaryData.party2_id_nr, 'Party 2: ID');
      } else {
        p2ID = comp.displayText('p2ID', appSummaryData.party2_passport_nr, 'Party 2: Passport Number');
      }
      personalDetails_row.appendChild(p2Name);
      personalDetails_row.appendChild(p2ID);
    }
  },
};
