import U from '../js/utils';
import svg from '../js/svg';

export default async (onclick) => {
  const eyeElement = await svg.eye();
  const eye = eyeElement.outerHTML;

  const div = `
  
    <div
      id="passBox"
      class="fixed inset-0 z-50 overflow-y-auto bg-opacity-50 bg-black ">

      <div class="card relative transform overflow-hidden">
      <h4>Change Password</h4>

      <div class="grid grid-cols-2 gap-8">

        <div class="input-group">
          <label for="newPassword1">New password</label>
          <input type="password" id="newPassword1" name="newPassword1" required val="password" />
          <div id="eyeDiv" class="absolute right top-10 right-2 cursor-pointer hover:text-primary" onclick="U.viewPass('newPassword1', 'eyeDiv')">${eye}</div>
          <span fb-for="newPassword1"></span>
        </div>

        <div class="input-group">
          <label for="newPassword2">Confirm password</label>
          <input type="password" id="newPassword2" name="newPassword2" required val="password" />
          <div id="eyeDiv" class="absolute right top-10 right-2 cursor-pointer hover:text-primary" onclick="U.viewPass('newPassword2', 'eyeDiv')">${eye}</div>
          <span fb-for="newPassword2"></span>
        </div>

      </div>

      <div
      class="flex justify-center items-center" >
      <button class="btn-outline mr-4" onclick="U.closeModal('passBox')">Cancel</button>
      <button id="updPass-btn"  onclick="U.closeModal('passBox') ${onclick ? `, ${onclick}'` : ''}">Okay</button>

    </div>



    
    `;

  const passBox = U.createHTMLElementFromString(div);
  document.body.prepend(passBox);
  //   return U.createHTMLElementFromString(div);
};
